import { useState } from "react";
import { Stack, Label, Separator, TextField, PrimaryButton, MessageBar, MessageBarType } from "@fluentui/react";
import { useMainState } from "../globalState/context";
import { SET_CONGRESS_CODE, SET_CONGRESS, SET_PROCESSING } from "../globalState/actions";

const CongressSelector = () => {
    const [message, setMessage] = useState("");
    const { state: { contact, congressCode, selectedCongress, processing }, dispatch } = useMainState();

    const congressCodeChanged = (_: any, value?: string) => {
        dispatch({ type: SET_CONGRESS_CODE, congressCode: value });
        dispatch({ type: SET_CONGRESS, congress: undefined });
    }

    const loadData = async () => {
        setMessage("");
        dispatch({ type: SET_PROCESSING, processing: true });
        const response = await fetch(`/api/congress?congressCode=${congressCode}`);

        if (response.ok) {
            const data = await response.json();
            dispatch({ type: SET_CONGRESS, congress: data });
        } else {
            if (response.status === 404) {
                setMessage("Kongres s požadovaným kódem nebyl nalezen. Kód může být nesprávný, kongres je plně obsazen nebo je registrace již uzavřena.")
            }
        }

        dispatch({ type: SET_PROCESSING, processing: false });
    };

    const getDateWithoutZone = (value: string): Date => {
        var date = new Date(value);
        date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
        return date;
    }

    const textFieldKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            loadData();
        }
    }

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack tokens={{ childrenGap: 10 }}>
                <div className="title">Vítejte {contact?.fullName}</div>
                <small>
                    Vložte prosím kód akce, které se chcete zúčastnit. Pokud kód neznáte, kontaktujte svého obchodního zástupce.
                </small>
            </Stack>

            <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Stack.Item grow>
                    <TextField
                        placeholder="Zadejte kód akce"
                        disabled={processing}
                        onChange={congressCodeChanged}
                        autoFocus
                        autoComplete="off"
                        onKeyUp={textFieldKeyUp}
                    />
                </Stack.Item>
                <PrimaryButton text="Načíst akci" disabled={processing || !congressCode} onClick={loadData} />
            </Stack>

            {message &&
                <MessageBar messageBarType={MessageBarType.warning} onDismiss={() => setMessage("")}>
                    {message}
                </MessageBar>
            }

            {congressCode && selectedCongress &&
                <Stack style={{ fontSize: "small" }} tokens={{ childrenGap: 10 }}>
                    <div>
                        V dalším textu znamená „my“, „nás“ nebo „naši“ společnost Fresenius Kabi s.r.o.,
                        se sídlem Na strži 1702/65, Nusle, 140 00 Praha 4, IČO: 25135228,
                        zapsanou v obchodním rejstříku pod spis. zn. C 52618 vedenou u Městského soudu v Praze.
                    </div>
                    <Separator>Podrobnosti o akci</Separator>
                    <div className="title">{selectedCongress.name}</div>
                    <div dangerouslySetInnerHTML={{ __html: selectedCongress.description?.replaceAll("\n", "<br />") }}></div>
                    <Stack horizontal horizontalAlign="stretch">
                        <Stack.Item className="half">
                            <Label>Datum zahájení</Label>
                            {getDateWithoutZone(selectedCongress.dateFrom).toLocaleString()}
                        </Stack.Item>
                        <Stack.Item className="half">
                            <Label>Datum ukončení</Label>
                            {getDateWithoutZone(selectedCongress.dateTo).toLocaleString()}
                        </Stack.Item>
                    </Stack>
                    <Stack horizontal horizontalAlign="stretch">
                        <Stack.Item className="half">
                            <Label>Specializace</Label>
                            {selectedCongress.specialization}
                        </Stack.Item>
                        <Stack.Item className="half">
                            <Label>Místo konání</Label>
                            {selectedCongress.location}
                        </Stack.Item>
                    </Stack>
                    <Stack horizontal horizontalAlign="stretch">
                        <Stack.Item className="half">
                            <Label>Volná místa</Label>
                            {selectedCongress.seatsInTotal - selectedCongress.takenSeats} / {selectedCongress.seatsInTotal}
                        </Stack.Item>
                    </Stack>
                </Stack>
            }
        </Stack>
    )
}

export default CongressSelector;
