import IAction from "../interfaces/IAction";
import IGlobalState from "../interfaces/IGlobalState";
import {
    SET_EMAIL, SET_TOKEN, SET_CONGRESS, SET_VALIDATED, SET_PROCESSING,
    SET_TOKEN_SENT, SET_CONTACT, SET_CONGRESS_CODE, SET_REG_DATA, SET_SCREEN
} from "./actions";

const saveState = (state: IGlobalState) => {
    try {
        const serializedState = JSON.stringify(state);
        sessionStorage.setItem("state", serializedState);
    } catch (e) { }
};

export const reducer = (state: IGlobalState, action: IAction) => {
    switch (action.type) {
        case SET_SCREEN: { 
            const updatedState = { ...state, screen: action.screen };
            saveState(updatedState);
            return updatedState;
        };
        case SET_EMAIL: return { ...state, email: action.email };
        case SET_TOKEN: return { ...state, token: action.token };
        case SET_CONGRESS: return { ...state, selectedCongress: action.congress };
        case SET_VALIDATED: {
            const updatedState = { ...state, validated: action.validated };
            saveState(updatedState);
            return updatedState;
        };
        case SET_PROCESSING: return { ...state, processing: action.processing };
        case SET_TOKEN_SENT: return { ...state, tokenSent: action.tokenSent };
        case SET_CONTACT: return { ...state, contact: action.contact };
        case SET_CONGRESS_CODE: return { ...state, congressCode: action.congressCode };
        case SET_REG_DATA: return { ...state, regData: action.regData };
        default: return state;
    }
};
