import { Stack } from "@fluentui/react";

const Finish = () => {
    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <div>
                Vaše žádost o poskytnutí sponzorského příspěvku a návrh Smlouvy o poskytnutí sponzorského příspěvku byl 
                právě odeslán společnosti Fresenius Kabi s.r.o. Stejnopis smlouvy najdete ve své emailové schránce.
            </div>
            <div>
                O vyhodnocení Vaší žádosti a dalším postupu Vás budeme informovat emailem na adresu, kterou u Vás máme 
                uloženou. Doporučujeme, abyste kontroloval/a také složku s nevyžádanou poštou.
            </div>
            <div>
                Pokud od nás nic neobdržíte do 30 dní, kontaktujte nás.
            </div>
            <div>
                Je nám ctí s Vámi spolupracovat.
            </div>
            <Stack horizontal horizontalAlign="end">
                <strong>Team Fresenius Kabi s.r.o.</strong>
            </Stack>
        </Stack>
    )
}

export default Finish;
