import { useEffect, useState } from "react";
import {
    TextField, DatePicker, Checkbox,
    PrimaryButton, Separator, Stack, addDays, Link
} from "@fluentui/react";
import { useCheckInput } from "../hooks/useCheckInput";
import { useMainState } from "../globalState/context";
import { SET_REG_DATA, SET_SCREEN } from "../globalState/actions";
import { formatDate } from "../common/dateOperations";
import { hasValue } from "../common/textOperations";
import IConfig from "../interfaces/IConfig";

const RegistrationForm = () => {
    const { state: { selectedCongress, regData, contact }, dispatch } = useMainState();
    const defaultStartDate = new Date(regData?.attendFrom ?? addDays((selectedCongress?.dateFrom ? new Date(selectedCongress?.dateFrom) : new Date()), -1));
    const defaultEndDate = new Date(regData?.attendTo ?? addDays((selectedCongress?.dateTo ? new Date(selectedCongress?.dateTo) : new Date()), 1));
    const [termsUrl, setTermsUrl] = useState("");
    const [reason, setReason] = useState(regData?.reason ?? "");
    const [attendFrom, setAttendFrom] = useState(defaultStartDate);
    const [attendTo, setAttendTo] = useState(defaultEndDate);
    const registrationFee = useCheckInput(regData?.registrationFee ?? false);
    const transportation = useCheckInput(regData?.transportation ?? false);
    const accomodation = useCheckInput(regData?.accomodation ?? false);

    useEffect(() => {
        const loadConfig = async () => {
            const response = await fetch(`/api/config?language=${contact?.language}`);
            const config: IConfig = await response.json()
            setTermsUrl(config.termsUrl);
        }
        loadConfig();
    }, [contact]);

    const reasonChanged = (_: any, value?: string) => {
        setReason(value ?? "");
    };

    const attendFromChanged = (value?: any) => {
        setAttendFrom(value);
    };

    const attendToChanged = (value?: any) => {
        setAttendTo(value);
    };

    const formIsValid = (): boolean => {
        return hasValue(reason) && reason.length > 3 && !!attendFrom && !!attendTo;
    };

    const nextStep = () => {
        dispatch({
            type: SET_REG_DATA,
            regData: {
                reason,
                attendFrom,
                attendTo,
                registrationFee: registrationFee.checked,
                transportation: transportation.checked,
                accomodation: accomodation.checked
            }
        });
        dispatch({ type: SET_SCREEN, screen: "contactForm" });
    }

    const termsLink = <Link href={termsUrl} target="_blank">Smluvních podmínkách</Link>;

    const labelLike = (text: string) => <div style={{ fontWeight: 600 }}>{text}</div>;

    return (
        <div>
            <Stack tokens={{ childrenGap: 10 }}>
                <div>
                    Pokud máte zájem o účast na výše uvedené akci, dále jen „Akce“, můžete v tomto prostředí vyplnit dále
                    požadované údaje a odeslat nám žádost o poskytnutí sponzorského příspěvku.
                </div>
                <div>
                    V takovém případě prosím uveďte, z jakých důvodů je pro Vás osobně účast na Akci důležitá a čím Vám
                    osobně prospěje (pokud budete považovat za přínos i to, že budete moci sdílet nově nabyté vědomosti s
                    kolegy na svém pracovišti, uveďte i to), v jakém rozsahu nás chcete požádat o poskytnutí sponzorského
                    příspěvku a zkontrolujte údaje o Akci. Pokud by nebylo možné zvolit Vámi požadovaný rozsah nebo pokud
                    by údaje nebyly správně, kontaktujte nás, prosím pomocí emailu níže.
                </div>
                <Separator>Registrační formulář</Separator>

                <TextField
                    label="Odůvodnění sponzorování"
                    placeholder="Uveďte prosím důvody pro to, abychom Vás sponzorovali na Akci."
                    multiline rows={5} maxLength={2000} required
                    value={reason}
                    onChange={reasonChanged}
                />
                <Stack horizontalAlign="stretch" grow>
                    <DatePicker
                        label="Zúčastním se od"
                        value={attendFrom}
                        isRequired
                        onSelectDate={attendFromChanged}
                        minDate={defaultStartDate}
                        maxDate={defaultEndDate}
                        formatDate={formatDate}
                        className="half"
                    />
                    <small>
                        Uveďte prosím datum, od kterého se chcete nacházet v místě konání Akce. Upozorňujeme,
                        že se může jednat nejvýše o jeden den před začátkem konání Akce. Další informace a omezení
                        jsou uvedeny v našich {termsLink}.
                    </small>
                </Stack>

                <Stack horizontalAlign="stretch" grow>
                    <DatePicker
                        label="Zúčastním se do"
                        value={attendTo}
                        isRequired
                        onSelectDate={attendToChanged}
                        minDate={defaultStartDate}
                        maxDate={defaultEndDate}
                        formatDate={formatDate}
                        className="half"
                    />
                    <small>
                        Uveďte prosím datum, do kterého se chcete nacházet v místě konání Akce. Upozorňujeme,
                        že se může jednat nejvýše o jeden den po konci konání Akce. Další informace a omezení jsou
                        uvedeny v našich {termsLink}.
                    </small>
                </Stack>

                <div>
                    Rozsah sponzorského příspěvku, o který chcete požádat (které položky za Vás v rámci
                    sponzorského příspěvku, pokud se rozhodneme ho poskytnout, nejvýše uhradíme):
                </div>

                <Stack tokens={{ childrenGap: 5 }}>
                    <Checkbox onRenderLabel={() => labelLike("Registrační poplatek za účast na Akci")} {...registrationFee} />
                    <small>
                        Prosím, zaškrtněte, pokud chcete, aby náš příspěvek pokrýval také poplatek za Vaši účast na Akci.
                        Upozorňujeme, že v příspěvku uhradíme pouze základní variantu registračního poplatku (například
                        takovou, která nezahrnuje společenské části či společenský program Akce). Příspěvek nesmí být
                        použit na registrační poplatek za účast dalších osob. Další informace a omezení jsou uvedeny v
                        našich {termsLink}.
                    </small>
                </Stack>

                <Stack tokens={{ childrenGap: 5 }}>
                    <Checkbox onRenderLabel={() => labelLike("Doprava související s Akcí")} {...transportation} />
                    <small>
                        Prosím, zaškrtněte, pokud chcete, aby náš příspěvek pokrýval také úhradu nákladů na Vaši dopravu Akci.
                        Upozorňujeme, že v příspěvku uhradíme pouze nejefektivnější formu dopravy (bez zbytečných prodloužení,
                        zastávek, zbytečných přestupů a jen v přiměřené třídě). Příspěvek nesmí být použit na náklady na dopravu
                        dalších osob. Další informace a omezení jsou uvedeny v našich {termsLink}.
                    </small>
                </Stack>

                <Stack tokens={{ childrenGap: 5 }}>
                    <Checkbox onRenderLabel={() => labelLike("Ubytování v místě konání Akce")} {...accomodation} />
                    <small>
                        Prosím, zaškrtněte, pokud chcete, aby náš příspěvek pokrýval také úhradu nákladů na Vaše ubytování v místě
                        konání Akce v jejím termínu. Upozorňujeme, že v příspěvku uhradíme pouze nejefektivnější formu ubytování
                        (bez zbytečných prodloužení a jen v přiměřené klasifikaci). Příspěvek nesmí být použit na náklady na ubytování
                        dalších osob, a to ani formou sdíleného ubytování. Další informace a omezení  jsou uvedeny v našich {termsLink}.
                    </small>
                </Stack>

                <div>
                    Kliknutím na tlačítko POKRAČOVAT přejdete ke kontrole Vašich údajů, které budou uvedené v textu žádosti o
                    poskytnutí sponzorského příspěvku a návrhu smlouvy o jeho poskytnutí. Tyto údaje musí být uvedeny správně,
                    věnujte jim proto, prosím, patřičnou pozornost.
                </div>

                <Stack horizontal verticalAlign="center" horizontalAlign="end">
                    <PrimaryButton text="Pokračovat" disabled={!formIsValid()} onClick={nextStep} />
                </Stack>

            </Stack>
        </div>
    )
}

export default RegistrationForm;
