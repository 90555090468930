import { useState } from "react";
import {
    TextField, DatePicker, PrimaryButton, Stack, Toggle, DefaultButton
} from "@fluentui/react";
import { useMainState } from "../globalState/context";
import { SET_CONTACT, SET_SCREEN } from "../globalState/actions";
import { formatDate, dateToStringWithoutTime } from "../common/dateOperations";
import { useCheckInput } from "../hooks/useCheckInput";
import { hasValue } from "../common/textOperations";

const ContactForm = () => {
    const { state: { contact }, dispatch } = useMainState();
    const useIc = useCheckInput(contact?.useIc ?? true);
    const [ico, setIco] = useState<string | undefined>(contact?.ico);
    const [placeOfBusiness, setPlaceOfBusiness] = useState<string | undefined>(contact?.placeOfBusiness);
    const [birthdate, setBirthdate] = useState<Date | undefined>(new Date(contact?.birthdate ?? new Date()));
    const [permanentResidence, setPermanentResidence] = useState<string | undefined>(contact?.permanentResidence);

    const formIsValid = (): boolean => {
        if (useIc.checked) {
            return hasValue(ico) && hasValue(placeOfBusiness);
        } else {
            return hasValue(birthdate) && hasValue(permanentResidence);
        }
    };

    const previousStep = () => {
        dispatch({ type: SET_SCREEN, screen: "regForm" });
    }

    const nextStep = () => {
        const { checked } = useIc;
        dispatch({
            type: SET_CONTACT,
            contact: {
                ...contact,
                useIc: checked,
                ico: checked ? ico : undefined,
                placeOfBusiness: checked ? placeOfBusiness : undefined,
                birthdate: checked ? undefined : dateToStringWithoutTime(birthdate),
                permanentResidence: checked ? undefined : permanentResidence
            }
        });
        dispatch({ type: SET_SCREEN, screen: "summary" });
    }

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <div>
                Doplňte a zkontrolujte, prosím, údaje, které se Vás týkají. Nesprávné údaje opravte a pokud údaj chybí, doplňte jej.
                Nebude-li to formulář umožňovat, kontaktujte nás na emailu níže.
            </div>

            <Stack horizontal horizontalAlign="stretch" grow tokens={{ childrenGap: 10 }}>
                <TextField label="Jméno" value={contact?.firstName} disabled className="half" />
                <TextField label="Příjmení" value={contact?.lastName} disabled className="half" />
            </Stack>

            <Stack horizontal horizontalAlign="stretch" grow tokens={{ childrenGap: 10 }}>
                <TextField label="Specializace" value={contact?.specialization} disabled className="half" />
                <TextField label="Zdravotnické povolání" value={contact?.profession} disabled className="half" />
            </Stack>

            <Stack horizontal horizontalAlign="stretch" grow tokens={{ childrenGap: 10 }}>
                <TextField label="Zaměstnavatel" value={contact?.employer} disabled className="half" />
                <TextField label="Pracoviště" value={contact?.department} disabled className="half" />
            </Stack>

            <Toggle
                onText="Chci vyplnit údaje jako podnikající osoba nebo společnost"
                offText="Chci vyplnit údaje jako nepodnikající osoba"
                {...useIc}
            />

            {useIc.checked ?
                <>
                    <TextField
                        label="IČO"
                        value={ico}
                        onChange={(_, value) => setIco(value)}
                        maxLength={20}
                        required
                        className="half"
                    />
                    <TextField
                        label="Místo podnikání"
                        value={placeOfBusiness}
                        onChange={(_, value) => setPlaceOfBusiness(value)}
                        maxLength={150}
                        required
                    />
                </>
                :
                <>
                    <DatePicker
                        label="Datum narození"
                        value={birthdate}
                        onSelectDate={(date) => setBirthdate(date ?? undefined)}
                        formatDate={formatDate}
                        isRequired
                        className="half"
                    />
                    <TextField
                        label="Trvalé bydliště"
                        value={permanentResidence}
                        onChange={(_, value) => setPermanentResidence(value)}
                        maxLength={150}
                        required
                    />
                </>
            }
            <Stack horizontal verticalAlign="center" horizontalAlign="end" tokens={{ childrenGap: 10 }}>
                <DefaultButton text="Zpět" onClick={previousStep} />
                <PrimaryButton text="Pokračovat" disabled={!formIsValid()} onClick={nextStep} />
            </Stack>

        </Stack>
    )
}

export default ContactForm;
