export const formatDate = (value?: Date): string => {
    return value ? value.toLocaleDateString() : "";
};

export const dateToStringWithoutTime = (value?: Date) => {
    if (value) {
        return `${value.getFullYear()}-${(value.getMonth() + 1).toString().padStart(2, "0")}-${value.getDate()}`;
    } else {
        return undefined;
    }
}
