import { useEffect, useState } from "react";
import {
    Checkbox, PrimaryButton, Link, MessageBar, MessageBarType, Stack, DefaultButton
} from "@fluentui/react";
import { useCheckInput } from "../hooks/useCheckInput";
import { useMainState } from "../globalState/context";
import { SET_PROCESSING, SET_SCREEN } from "../globalState/actions";
import IConfig from "../interfaces/IConfig";

const Summary = () => {
    const { state: { contact, regData, processing, congressCode, selectedCongress }, dispatch } = useMainState();
    const [message, setMessage] = useState("");
    const [termsUrl, setTermsUrl] = useState("");
    const [personalDataUrl, setPersonalData] = useState("");
    const check1 = useCheckInput(false);
    const check2 = useCheckInput(false);
    const check3 = useCheckInput(false);
    const check4 = useCheckInput(false);
    const readOnlyForm: boolean = processing ?? false;

    useEffect(() => {
        const loadConfig = async () => {
            const response = await fetch(`/api/config?language=${contact?.language}`);
            const config: IConfig = await response.json()
            setTermsUrl(config.termsUrl);
            setPersonalData(config.personalDataUrl);
        }
        loadConfig();
    }, [contact]);

    const formIsValid = (): boolean => {
        return check1.checked && check2.checked && check3.checked && check4.checked;
    };

    const convertDate = (value?: Date): Date => {
        var date = new Date(value ?? new Date());
        date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
        return date;
    }

    const downloadAgreement = async () => {
        const response = await fetch(`/api/agreement?language=${contact?.language}`);
        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = "agreement-template.pdf";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }

    const register = async () => {
        dispatch({ type: SET_PROCESSING, processing: true });

        const response = await fetch("/api/register", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                congressId: selectedCongress?.id,
                contactId: contact?.id,
                dateFrom: convertDate(regData?.attendFrom),
                dateTo: convertDate(regData?.attendTo),
                registrationFee: regData?.registrationFee,
                transportation: regData?.transportation,
                accommodation: regData?.accomodation,
                reason: regData?.reason,
                congressCode,
                contact
            })
        });

        if (!response.ok) {
            if (response.status === 401) {
                setMessage("Kód pro vybranou akci není platný.")
            } else if (response.status === 400) {
                setMessage("Omlouváme se, ale akce je již plně obsazena.")
            } else if (response.status === 409) {
                setMessage("Na tuto akci jste již zaregistrován/a.")
            } else {
                setMessage("Došlo k neočekávané chybě. Kontaktujte prosím naši podporu na compliance-czsk@fresenius-kabi.com")
            }
        }

        dispatch({ type: SET_PROCESSING, processing: false });

        if (response.ok) {
            setMessage("");
            dispatch({ type: SET_SCREEN, screen: "finish" });
        }
    };

    const previousStep = () => {
        dispatch({ type: SET_SCREEN, screen: "contactForm" });
    }

    const renderAgreementDocument = () => (
        <div style={{ marginLeft: 4 }}>
            Smlouva o poskytnutí sponzorského příspěvku, jak je dostupná na <Link href="#agreement" onClick={downloadAgreement}>tomto odkazu</Link>, představuje mou skutečnou vůli a mnou učiněný návrh na uzavření takové smlouvy, jsem jím vázán/a a chci jej takto odeslat společnosti Fresenius Kabi, s.r.o.
        </div>
    );

    const renderTermsAndConditionsAgreement = () => (
        <div style={{ marginLeft: 4 }}>
            Přečetl/a jsem a seznámil/a se s <Link href={termsUrl} target="_blank">Smluvními podmínkami</Link>, bylo umožněno se dotázat na jejich obsah byly mi poskytnuty potřebné odpovědi.
        </div>
    );

    const renderPersonalDataAgreement = () => (
        <div style={{ marginLeft: 4 }}>
            Přečetl/a jsem a seznámil/a jsem se se <Link href={personalDataUrl} target="_blank">Zásadami zpracování osobních údajů</Link>, bylo umožněno se dotázat na jejich obsah, byly mi poskytnuty potřebné odpovědi.
        </div>
    );

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <div>
                Já, <strong>{contact?.firstName} {contact?.lastName}</strong>, tímto žádám Fresenius Kabi s.r.o. (oba dále přesněji určeni v
                návrhu smlouvy níže), o poskytnutí sponzorský příspěvek na mou účast na <strong>{selectedCongress?.category} - {selectedCongress?.name}</strong>.
                Mám za to, že odborný program <strong>{selectedCongress?.name}</strong> souvisí s mou specializací v oboru <strong>{selectedCongress?.specialization}</strong>,
                a to z následujících důvodů:<br />
                {regData?.reason}
            </div>

            <div>
                Žádám Vás tedy o poskytnutí sponzorského příspěvku na následující položky:<br />
                <ol>
                    {regData?.registrationFee && <li>Registrační poplatek za účast na Akci</li>}
                    {regData?.transportation && <li>Doprava související s Akcí</li>}
                    {regData?.accomodation && <li>
                        Ubytování v místě konání Akce ({regData?.attendFrom?.toLocaleDateString()} - {regData?.attendTo?.toLocaleDateString()})
                    </li>}
                </ol>
            </div>

            <div>
                Beru na vědomí a souhlasím, že pro poskytnutí sponzorského příspěvku budete požadovat splnění dalších podmínek, které budou
                vyjádřeny ve Smlouvě o poskytnutí sponzorského příspěvku, jejíž návrh, jak je dostupný na tomto odkazu, Vám posílám podepsaný
                z mé strany současně s touto žádostí a jejíž nedílnou přílohu tvoří Smluvní podmínky. S ustanoveními a podmínkami vyjádřenými
                v těchto dokumentech tímto vyslovuji svůj souhlas.
            </div>

            <div>
                Já, <strong>{contact?.firstName} {contact?.lastName}</strong>, kliknutím na tlačítko ODESLAT prohlašuji, že
            </div>

            <Checkbox
                label="Výše uvedená žádost o poskytnutí sponzorského příspěvku, představuje mou skutečnou vůli a chci ji odeslat společnosti Fresenius Kabi, s.r.o."
                {...check1}
                disabled={readOnlyForm}
            />
            <Checkbox onRenderLabel={renderAgreementDocument} {...check2} disabled={readOnlyForm} />
            <Checkbox onRenderLabel={renderTermsAndConditionsAgreement} {...check3} disabled={readOnlyForm} />
            <Checkbox onRenderLabel={renderPersonalDataAgreement} {...check4} disabled={readOnlyForm} />

            <div>
                Kliknutím na tlačítko ODESLAT odešlete Vaši žádost a návrh smlouvy, do našeho interního systému k posouzení.
                Současně Vám stejnopis smlouvy přijde na emailovou adresu, kterou u Vás máme uloženou. Doporučujeme, abyste
                kontroloval/a také složku s nevyžádanou poštou.
            </div>

            <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
                <DefaultButton text="Zpět" onClick={previousStep} disabled={readOnlyForm} />
                <PrimaryButton text="Odeslat" disabled={!formIsValid() || readOnlyForm} onClick={register} />
            </Stack>

            {message &&
                <MessageBar messageBarType={MessageBarType.warning} onDismiss={() => setMessage("")}>
                    {message}
                </MessageBar>
            }
        </Stack>
    )
}

export default Summary;
