export const hasValue = (value: any): boolean => {
    const has: boolean = value !== undefined && value !== null;
    const valueType: any = typeof value;

    if (has && valueType === "string") {
        return value.toString().length > 0;
    }

    return has;
};
