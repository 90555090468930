import { useState } from "react";
import { Stack, TextField, PrimaryButton, MessageBar, MessageBarType } from "@fluentui/react";
import { useMainState } from "../globalState/context";
import { SET_EMAIL, SET_PROCESSING, SET_TOKEN_SENT } from "../globalState/actions";

// eslint-disable-next-line
const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const RequestToken = () => {
    const [emailIsValid, setEmailIsValid] = useState(false);
    const { state: { email, processing }, dispatch } = useMainState();

    const emailChanged = (_: any, value?: string) => {
        dispatch({ type: SET_EMAIL, email: value });

        if (!value) { setEmailIsValid(false); return; }
        const isValid = emailRegex.test(value);
        setEmailIsValid(isValid);
    };

    const requestToken = async () => {
        try {
            dispatch({ type: SET_PROCESSING, processing: true });
            await fetch(`/api/token/request?email=${email}`, { method: "POST" });
            dispatch({ type: SET_PROCESSING, processing: false });
            dispatch({ type: SET_TOKEN_SENT, tokenSent: true });
        } catch (error) {
            dispatch({ type: SET_PROCESSING, processing: false });
        }
    };

    const textFieldKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            requestToken();
        }
    }

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Stack.Item grow>
                    <TextField
                        placeholder="Vložte svou e-mailovou adresu"
                        disabled={processing}
                        onChange={emailChanged}
                        autoFocus
                        onKeyUp={textFieldKeyUp}
                    />
                </Stack.Item>
                <PrimaryButton text="Poslat kód" disabled={!emailIsValid || processing} onClick={requestToken} />
            </Stack>
            <MessageBar messageBarType={MessageBarType.info}>
                Potřebujeme ověřit, že jste vlastníkem e-mailové adresy.
                Obdržíte e-mail s ověřovacím kódem, který je potřeba zadat v dalším kroku.
            </MessageBar>
        </Stack>
    )
}

export default RequestToken;
