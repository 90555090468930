import { Stack } from "@fluentui/react";
import logo from "../images/logo.jpg";
import cover from "../images/cover.jpg";
import { useMainState } from "../globalState/context";
import { SET_CONGRESS, SET_CONGRESS_CODE, SET_REG_DATA, SET_SCREEN } from "../globalState/actions";

const Header = () => {
    const { dispatch } = useMainState();

    const logoClicked = () => {
        dispatch({ type: SET_CONGRESS, congress: undefined });
        dispatch({ type: SET_CONGRESS_CODE, congressCode: undefined });
        dispatch({ type: SET_REG_DATA, regData: undefined });
        dispatch({ type: SET_SCREEN, screen: "regForm" });
    }

    return (
        <Stack>
            <Stack horizontal={true} verticalAlign="center" tokens={{ childrenGap: 30 }}>
                <div style={{ cursor: "pointer" }} onClick={logoClicked}>
                    <img src={logo} alt="Fresenius Kabi Logo" height={40} />
                </div>
                <div style={{ color: "#0063be", fontSize: 24 }}>Registrace na akci</div>
            </Stack>
            <img src={cover} alt="Congress cover" style={{ margin: "25px -30px 15px -30px", opacity: 0.4 }} />
        </Stack>
    )
}

export default Header;
