export const SET_SCREEN = "SET_SCREEN";
export const SET_EMAIL = "SET_EMAIL";
export const SET_TOKEN = "SET_TOKEN";
export const SET_CONGRESS = "SET_CONGRESS";
export const SET_VALIDATED = "SET_VALIDATED";
export const SET_PROCESSING = "SET_PROCESSING";
export const SET_TOKEN_SENT = "SET_TOKEN_SENT";
export const SET_CONTACT = "SET_CONTACT";
export const SET_CONGRESS_CODE = "SET_CONGRESS_CODE";
export const SET_REG_DATA = "SET_REG_DATA";
